import { useContext } from "react";
import { useParams } from "react-router-dom";
import { langContext } from "../context/langContext";
import { dataContext } from "../context/dataContext";
import Categorie from "../components/categorie/Categorie";

const CategoriePage = () => {
  const { locale } = useContext(langContext);
  const { dataProducts } = useContext(dataContext);
  const { category } = useParams();

  const handleCategory = (category) => {
    if (category === "AdaptadoresyConectores") {
      return "Adaptadores y Conectores";
    }
    if (category === "BaflesyBocinas") {
      return "Bafles y Bocinas";
    }
    return category;
  };

  const handleImgCategory = (category) => {
    let urlImg = "";
    switch (category) {
      case "Cables":
        urlImg = "https://i.ibb.co/NmFv3Cv/cables.png";
        break;
      case "AdaptadoresyConectores":
        urlImg = "https://i.ibb.co/JzWbkwh/conectores.png";
        break;
      case "Pedales":
        urlImg = "https://i.ibb.co/BLx1GCK/pedales.png";
        break;
      case "BaflesyBocinas":
        urlImg = "https://i.ibb.co/LgKNSVw/bocinas.png";
        break;
      case "Microfonos":
        urlImg = "https://i.ibb.co/dMqdQGy/micros.png";
        break;
      case "Bumpers":
        urlImg = "https://i.ibb.co/SnrGLcT/bumpers.png";
        break;
      default:
        urlImg = "";
        break;
    }
    return urlImg;
  };

  const handleTitleCategory = (category, locale) => {
    if (category === "AdaptadoresyConectores") {
      return locale === "es-MX"
        ? "Adaptadores y Conectores"
        : "Adapters and Connectors";
    }
    if (category === "BaflesyBocinas") {
      return locale === "es-MX" ? "Bafles y Bocinas" : "Baffles and Speakrs";
    }
    if (category === "Pedales") {
      return locale === "es-MX" ? "Pedales" : "Pedals";
    }
    if (category === "Microfonos") {
      return locale === "es-MX"
        ? "Microfonos and Mixers"
        : "Microphones y Mixers";
    }
    return category;
  };

  const productsCategory = dataProducts?.filter(
    (el) => el.category === handleCategory(category).toLowerCase()
  );
  const titleCategory = handleTitleCategory(category, locale);
  const imgCategory = handleImgCategory(category);

  const dataCategory = {
    title: titleCategory,
    imgCategory,
    data: productsCategory,
  };

  return (
    <div>
      <Categorie infoCat={dataCategory} />
    </div>
  );
};

export default CategoriePage;
