import React from "react";
import LOGO from "../../assets/imgs/log.png";
import POLICY from "../../assets/files/Politica de privacidad.pdf";
import TERMS from "../../assets/files/Terminos de uso.pdf";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import "./Footer.css";

const Footer = () => {
  return (
    <section className="Footer">
      <div className="footer__first">
        <div>
          <img className="footer__logo" src={LOGO} alt="logo mrs" />
        </div>
        <div className="footer__links">
          <h4>
            <FormattedMessage
              id="categories.title"
              defaultMessage="Categories"
            />
          </h4>
          <ul>
            <li>
              <Link to="/categories/Cables">Cables</Link>
            </li>
            <li>
              <Link to="/categories/Pedales">
                <FormattedMessage id="char.pedals" defaultMessage="Pedals" />
              </Link>
            </li>
            <li>
              <Link to="/categories/AdaptadoresyConectores">
                <FormattedMessage
                  id="char.Adap-Conect"
                  defaultMessage="Adapters and Connectors"
                />
              </Link>
            </li>
            <li>
              <Link to="/categories/BaflesyBocinas">
                <FormattedMessage
                  id="char.BafyBoci"
                  defaultMessage="Baffles and Speakrs"
                />
              </Link>
            </li>
            <li>
              <Link to="/categories/Microfonos">
                <FormattedMessage
                  id="char.Microfonos"
                  defaultMessage="Microphones"
                />
              </Link>
            </li>
            <li>
              <Link to="/categories/Bumpers">Bumpers</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer__second">
        <p>© Copyright 2022, MRS, S.A. de C.V.</p>
        <div className="footer__terms">
          <a className="terms terms-left" href={TERMS} download>
            Terms of service
          </a>
          <a className="terms terms-right" href={POLICY} download>
            Privacy Policy
          </a>
        </div>
      </div>
    </section>
  );
};

export default Footer;
