import { createContext, useState, useEffect } from "react";
import { getProductsService } from "../services/products";

const dataContext = createContext();

const DataProvider = ({ children }) => {
  const [dataProducts, setDataProducts] = useState([]);

  useEffect(() => {
    getProductsService().then((res) => setDataProducts(res));
  }, []);

  const datos = {
    dataProducts,
  };

  return <dataContext.Provider value={datos}>{children}</dataContext.Provider>;
};

export { DataProvider, dataContext };
