import React from "react";
import IMG from "../../assets/imgs/pexels-andre-moura-2897776.jpg";
import { FormattedMessage } from "react-intl";
import "./profesionals.css";

const Profesionals = () => {
  return (
    <section className="profesionals">
      <div className="p__imageCont">
        <img className="p__image" src={IMG} alt="equipo de audio profesional" />
      </div>
      <div className="p__textCont">
        <h3>
          <FormattedMessage
            id="profesionals.title"
            defaultMessage="Professional articles present in the best stages"
          />
        </h3>
        <div className="prof__figures">
          <div className="figures__line"></div>
          <div className="figures__square"></div>
        </div>
        <p>
          <FormattedMessage
            id="profesionals.text"
            defaultMessage="Mrs is characterized by being present in the best scenarios, giving her clients support and security in their presentations. With products that exceed expectations in quality and performance, we have achieved the trust and preference of high-level users in the musical field."
          />
        </p>
      </div>
    </section>
  );
};

export default Profesionals;
