import React, { useContext } from "react";
import Hero from "../components/hero/Hero";
import Categories from "../components/categories/Categories";
import Tecninc from "../components/tecnic/Tecninc";
import TopProducts from "../components/topProducts/TopProducts";
import Profesionals from "../components/profesionals/Profesionals";
import { dataContext } from "../context/dataContext";

const HomePage = () => {
  const { dataProducts } = useContext(dataContext);

  const topProducts = dataProducts?.filter((prod) => prod.top === true);

  return (
    <div>
      <Hero />
      <Categories />
      <Tecninc />
      <TopProducts topProducts={topProducts} />
      <Profesionals />
    </div>
  );
};

export default HomePage;
