import React, { useState, useContext } from "react";
import { langContext } from "../../context/langContext";
import LOGO from "../../assets/imgs/l.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import "./header.css";

const Header = () => {
  const { setLang, changeLang } = useContext(langContext);
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuDrop, setShowMenuDrop] = useState(false);

  return (
    <section className="header">
      <nav className="header__container">
        <div>
          <Link to="/">
            <img className="header__image" src={LOGO} alt="logotipo mrs" />
          </Link>
        </div>
        <ul className={`header__list ${showMenu ? "show--menu" : ""}`}>
          <li className="header__dropDown list__item">
            <span onClick={() => setShowMenuDrop(!showMenuDrop)}>
              <FormattedMessage
                id="head.list-link1"
                defaultMessage="Products"
              />
            </span>
            <ul className={`dropDown ${showMenuDrop ? "show" : ""}`}>
              <li>
                <Link to="/categories/Cables">Cables</Link>
              </li>
              <li>
                <Link to="/categories/AdaptadoresyConectores">
                  Adaptadores y conectores
                </Link>
              </li>
              <li>
                <Link to="/categories/Pedales">Pedales</Link>
              </li>
              <li>
                <Link to="/categories/BaflesyBocinas">Bafles y Bocinas</Link>
              </li>
              <li>
                <Link to="/categories/Microfonos">Microfonos y Mixers</Link>
              </li>
              <li>
                <Link to="/categories/Bumpers">Bumpers</Link>
              </li>
            </ul>
          </li>
          <li className="list__item">
            <Link to="/contact">
              <FormattedMessage id="head.list-link2" defaultMessage="Contact" />
            </Link>
          </li>
          <li className="list__item">
            <Link to="/distributors">
              <FormattedMessage
                id="head.list-link3"
                defaultMessage="Distributors"
              />
            </Link>
          </li>
        </ul>
        <div className="header__btnLang" onClick={() => setLang(changeLang)}>
          <span className="header__langText">
            <FormattedMessage id="head.btn-lang" defaultMessage="us" />
          </span>
        </div>
        <div
          className="header__hamburgerMenu"
          onClick={() => setShowMenu(!showMenu)}
        >
          <GiHamburgerMenu />
        </div>
      </nav>
    </section>
  );
};

export default Header;
