import React from "react";
import POLICY from "../../assets/files/Politica de privacidad.pdf";
import TERMS from "../../assets/files/Terminos de uso.pdf";
import { FaMailBulk } from "react-icons/fa";
import { BsFillPhoneFill, BsFacebook } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import LOADER from "../../assets/imgs/loader.svg";
import { FormattedMessage } from "react-intl";
import { postEmailMe } from "../../services/email";

import "./contact.css";
import { useState } from "react";

const initialForm = {
  name: "",
  email: "",
  phone: "",
  message: "",
  termsAgree: "off",
};
const Contact = () => {
  const [contactForm, setContactForm] = useState(initialForm);
  const [loader, setLoader] = useState(false);
  const [succesMsg, setScuccesMsg] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    postEmailMe(contactForm).then((data) => {
      console.log(data);
      setLoader(false);
      setScuccesMsg(true);
      e.target.reset();
      setTimeout(() => {
        setScuccesMsg(false);
      }, 10000);
    });
  };

  const handleChange = (e) => {
    setContactForm({
      ...contactForm,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="contact">
      <div className="contact__hero">
        <div className="contact__titleCont">
          <h2 className="contact__title">
            <FormattedMessage
              id="contact.titleHero"
              defaultMessage="Contact Us"
            />
          </h2>
        </div>
      </div>
      <div className="contact__container">
        <div className="contact__form">
          <form action="" className="form" onSubmit={handleSubmit}>
            <input
              className="form__inputText"
              type="text"
              name="name"
              placeholder="Name"
              required
              onChange={handleChange}
            />
            <input
              className="form__inputText"
              type="email"
              name="email"
              placeholder="Email"
              required
              onChange={handleChange}
            />
            <input
              className="form__inputText"
              type="text"
              name="phone"
              placeholder="Phone"
              required
              onChange={handleChange}
            />
            <textarea
              cols="30"
              rows="10"
              placeholder="Message"
              name="message"
              required
              onChange={handleChange}
            ></textarea>
            <div className="form__terms">
              <input
                className="terms__check"
                type="checkbox"
                name="termsAgree"
                required
                onChange={handleChange}
              />
              <label className="terms__text">
                <FormattedMessage
                  id="contact.terms1"
                  defaultMessage="I agree with the"
                />
                <a className="terms__link" href={TERMS} download>
                  <FormattedMessage
                    id="contact.terms2"
                    defaultMessage="Terms of use"
                  />
                </a>
                <FormattedMessage id="contact.terms3" defaultMessage="and" />
                <a className="terms__link" href={POLICY} download>
                  <FormattedMessage
                    id="contact.terms4"
                    defaultMessage="Privacy Policy"
                  />
                </a>
                <FormattedMessage
                  id="contact.terms5"
                  defaultMessage="and i declare that I have read the information that is required"
                />
              </label>
            </div>
            <button type="submit">
              <FormattedMessage id="contact.btnSend" defaultMessage="Send" />
            </button>
            <img className={loader ? "" : "none"} src={LOADER} alt="loader" />
            <span
              className={succesMsg ? "successMessage" : "none successMessage"}
            >
              <FormattedMessage
                id="contact.succesMsg"
                defaultMessage="Message sended"
              />
            </span>
          </form>
        </div>
        <div className="contact__right">
          <div className="contact__btns">
            <a className="btns__btn" href="tel:+525546142548">
              <div className="btn__icon">
                <BsFillPhoneFill />
              </div>
              <div className="btn__text">
                <span>
                  <FormattedMessage
                    id="contact.btnPhone"
                    defaultMessage="Phone"
                  />
                </span>
                <span>+55 5546142548</span>
              </div>
            </a>
            <a className="btns__btn" href="mailto:info@mrs.com">
              <div className="btn__icon">
                <FaMailBulk />
              </div>
              <div className="btn__text">
                <span>
                  <FormattedMessage
                    id="contact.btnEmail"
                    defaultMessage="Email"
                  />
                </span>
                <span>info@mrs.com</span>
              </div>
            </a>
          </div>
          <div className="contact__socials">
            <div className="socials__item">
              <BsFacebook />
            </div>
            <div className="socials__item">
              <AiFillTwitterCircle />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
