import "./App.css";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import DistributorsPage from "./pages/DistributorsPage";
import CategoriesPage from "./pages/CategoriePage";
import ProductPage from "./pages/ProductPage";
import { LangProvider } from "./context/langContext";
import { DataProvider } from "./context/dataContext";

function App() {
  return (
    <DataProvider>
      <LangProvider>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/distributors" element={<DistributorsPage />} />
            <Route path="/categories" element={<CategoriesPage />}>
              <Route
                path="/categories/:category"
                element={<CategoriesPage />}
              />
            </Route>
            <Route path="/products" element={<ProductPage />}>
              <Route path="/products/:product" element={<ProductPage />} />
            </Route>
            <Route path="*" element={<h1>Page not found</h1>} />
          </Routes>
          <Footer />
        </Router>
      </LangProvider>
    </DataProvider>
  );
}

export default App;
