export const postEmailMe = async (params) => {
  // const url = "http://localhost:4000/api/v1/email/send-me"; //dev
  const url = "https://back-mrs-production.up.railway.app/api/v1/email/send-me"; //prod
  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-type": "application/json",
    },
  });
  const resJson = res.json();
  return resJson;
};
