import React from "react";
import { useState, useContext } from "react";
import { MdContactSupport, MdStoreMallDirectory } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { langContext } from "../../context/langContext";
import "./product.css";

const Product = ({ infoProd }) => {
  const { rutas, modelo, esLang, usLang } = infoProd;
  const { locale } = useContext(langContext);
  const [mainImg, setmainImg] = useState(rutas[0]);

  const handleImageMain = (index) => {
    setmainImg(index);
  };

  const lang = locale === "en-US" ? usLang : esLang;

  return (
    <div className="product">
      <div className="product__images">
        <div className="product__mainBack">
          <img className="product__mainFront" src={mainImg} alt="mrs product" />
        </div>
        <div className="product__thumbs">
          {rutas?.map((el, index) => (
            <img
              key={index}
              src={el}
              alt="mrs product"
              className="product__thumb"
              onClick={() => handleImageMain(rutas[index])}
            />
          ))}
        </div>
      </div>
      <div className="product__text">
        <h3>{modelo}</h3>
        <p>{lang?.description}</p>
        <div className="product__features">
          <span>
            <FormattedMessage
              id="product.features"
              defaultMessage="Categories"
            />
          </span>
          <ul>
            {lang?.features?.map((el, index) => (
              <li key={index}>{el}</li>
            ))}
          </ul>
        </div>
        <div className="product__btms">
          <Link className="product__btm" to="/distributors">
            <MdStoreMallDirectory className="product__btmIcon" />
            <span>
              <FormattedMessage
                id="product.find"
                defaultMessage="Find a Dealer"
              />
            </span>
          </Link>
          <Link className="product__btm" to="/contact">
            <MdContactSupport className="product__btmIcon" />
            <span>
              <FormattedMessage
                id="product.cuestion"
                defaultMessage="Have a Cuestion?"
              />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Product;
