import React from "react";
import { FormattedMessage } from "react-intl";
import "./distributors.css";

const Distributors = () => {
  return (
    <div className="distributors">
      <div className="distributors__hero">
        <div className="distributors__heroFront">
          <h2 className="distributors__text">
            <FormattedMessage
              id="dis.titleHero"
              defaultMessage="Distributors"
            />
          </h2>
        </div>
      </div>
      <div className="distributors__container">
        <div className="distributors__list">
          <article className="dis__card">
            <img
              className="dis__image"
              src="https://i.ibb.co/TLkjGJz/LOGO-AMAZON.png"
              alt="icono distriuidor"
            />
            <div className="dis__text">
              <span>Amazon</span>
              <a href="https://www.amazon.com.mx/" target="blank">
                www.amazon.com.mx
              </a>
            </div>
          </article>
          <article className="dis__card">
            <img
              className="dis__image"
              src="https://i.ibb.co/FYsBV4C/LOGO-TOPMUSIC.png"
              alt="icono distriuidor"
            />
            <div className="dis__text">
              <span>Top Music</span>
              <a href="https://topmusic.com.mx/" target="blank">
                www.topmusic.com.mx
              </a>
            </div>
          </article>
          <article className="dis__card">
            <img
              className="dis__image"
              src="https://i.ibb.co/ctHnjYd/LOGO-MUSICCLUB.png"
              alt="icono distriuidor"
            />
            <div className="dis__text">
              <span>Music Club</span>
              <a href="http://www.musicclub.com.mx/" target="blank">
                www.musicclub.com.mx
              </a>
            </div>
          </article>
          <article className="dis__card">
            <img
              className="dis__image"
              src="https://i.ibb.co/tcvcL0d/LOGO-HMC.png"
              alt="icono distriuidor"
            />
            <div className="dis__text">
              <span>Hermes Music</span>
              <a href="https://hermesmusicclub.mx/" target="blank">
                www.hermesmusicclub.mx
              </a>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Distributors;
