import React, { useRef, useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { FormattedMessage } from "react-intl";

import "./topProducts.css";

const renderTopProduct = (data) => {
  const { imgUrlBg, modelo, mRef, id } = data;
  return (
    <article key={id} className="cardProduct">
      <img className="cP__image" src={imgUrlBg} alt="imagen de producto" />
      <div className="cP__text">
        <h3>{mRef}</h3>
        <span className="">{modelo}</span>
      </div>
    </article>
  );
};

const TopProducts = ({ topProducts }) => {
  const numProducts = topProducts.length;
  const listRef = useRef();
  const [width, setWidth] = useState(window.innerWidth);
  const [currentSlide, setcurrentSlide] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  const prevSlide = () => {
    if (width > 768 && width < 1080) {
      setcurrentSlide(
        currentSlide === 0 ? numProducts - 2 : (prev) => prev - 1
      );
    } else if (width > 1080 && width < 1200) {
      setcurrentSlide(
        currentSlide === 0 ? numProducts - 3 : (prev) => prev - 1
      );
    } else if (width > 1200) {
      setcurrentSlide(
        currentSlide === 0 ? numProducts - 4 : (prev) => prev - 1
      );
    } else {
      setcurrentSlide(
        currentSlide === 0 ? numProducts - 1 : (prev) => prev - 1
      );
    }
  };

  const nextSlide = () => {
    if (width > 768 && width < 1080) {
      setcurrentSlide(
        currentSlide === numProducts - 2 ? 0 : (prev) => prev + 1
      );
    } else if (width > 1080 && width < 1200) {
      setcurrentSlide(
        currentSlide === numProducts - 3 ? 0 : (prev) => prev + 1
      );
    } else if (width > 1200) {
      setcurrentSlide(
        currentSlide === numProducts - 4 ? 0 : (prev) => prev + 1
      );
    } else {
      setcurrentSlide(
        currentSlide === numProducts - 1 ? 0 : (prev) => prev + 1
      );
    }
  };

  return (
    <section className="topProducts">
      <h2>
        <FormattedMessage id="products.title" defaultMessage="Products" />
      </h2>
      <div className="product__container">
        <div
          className="product__list"
          ref={listRef}
          style={{
            transform: `translateX(-${currentSlide * 290}px)`,
            width: `${numProducts * 290}px`,
          }}
        >
          {topProducts.map((el) => renderTopProduct(el))}
        </div>
        <div className="arrow arrow_prev" onClick={prevSlide}>
          <IoIosArrowBack />
        </div>
        <div className="arrow arrow_next" onClick={nextSlide}>
          <IoIosArrowForward />
        </div>
      </div>
    </section>
  );
};

export default TopProducts;
