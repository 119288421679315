import { useContext } from "react";
import { useParams } from "react-router-dom";
import { dataContext } from "../context/dataContext";
import Product from "../components/product/Product";

const ProductPage = () => {
  const { dataProducts } = useContext(dataContext);
  const { product } = useParams();

  const productFind = dataProducts?.find((el) => el.mRef === product);

  if (!productFind) {
    return <div></div>;
  }

  return (
    <div>
      <Product infoProd={productFind} />
    </div>
  );
};

export default ProductPage;
