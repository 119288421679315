import React from "react";
import Distributors from "../components/distributors/Distributors";

const DistributorsPage = () => {
  return (
    <div>
      <div>
        <Distributors />
      </div>
    </div>
  );
};

export default DistributorsPage;
