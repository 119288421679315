import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { langContext } from "../../context/langContext";
import "./categorie.css";

const renderProduct = (data, locale) => {
  const { imgUrlBg, esLang, modelo, id, mRef, usLang } = data;
  const lang = locale === "en-US" ? usLang : esLang;
  return (
    <Link to={`/products/${mRef}`} key={id} className="cardProduct2">
      <img className="cardProduct2__img" src={imgUrlBg} alt="producto" />
      <div className="cardProduct2__figures">
        <div className="line__left"></div>
        <div className="square__center"></div>
        <div className="line__right"></div>
      </div>
      <h3 className="cardProduct2__name">{modelo}</h3>
      <p className="cardProducct__des">{lang.description__small}</p>
    </Link>
  );
};

const Categorie = ({ infoCat }) => {
  const { locale } = useContext(langContext);
  const { title, data, imgCategory } = infoCat;
  return (
    <section className="categorie">
      <div
        className="categorie__hero"
        style={{
          backgroundImage: `url(${imgCategory})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="categorie__heroFront">
          <h2>{title}</h2>
        </div>
      </div>
      <div className="categorie__container">
        <div className="categorie__list">
          {data?.map((el) => renderProduct(el, locale))}
        </div>
      </div>
    </section>
  );
};

export default Categorie;
