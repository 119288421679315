import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import "./categories.css";

const Categories = () => {
  return (
    <section className="categories">
      <div className="categories__container">
        <Link
          to="/categories/Cables"
          style={{
            backgroundImage: `url("https://i.ibb.co/NmFv3Cv/cables.png")`,
          }}
          className="categories__item"
        >
          <div className="item__front">
            <span>Cables</span>
          </div>
        </Link>
        <Link
          to="/categories/AdaptadoresyConectores"
          style={{
            backgroundImage: `url("https://i.ibb.co/JzWbkwh/conectores.png")`,
          }}
          className="categories__item"
        >
          <div className="item__front">
            <span>
              <FormattedMessage
                id="char.Adap-Conect"
                defaultMessage="Adapters and Connectors"
              />
            </span>
          </div>
        </Link>
        <Link
          to="/categories/Pedales"
          style={{
            backgroundImage: `url(https://i.ibb.co/BLx1GCK/pedales.png)`,
          }}
          className="categories__item"
        >
          <div className="item__front">
            <span>
              <FormattedMessage id="char.pedals" defaultMessage="Pedals" />
            </span>
          </div>
        </Link>
        <Link
          to="/categories/BaflesyBocinas"
          style={{
            backgroundImage: `url("https://i.ibb.co/LgKNSVw/bocinas.png")`,
          }}
          className="categories__item"
        >
          <div className="item__front">
            <span>
              <FormattedMessage
                id="char.BafyBoci"
                defaultMessage="Baffles and Speakrs"
              />
            </span>
          </div>
        </Link>
        <Link
          to="/categories/Microfonos"
          style={{
            backgroundImage: `url("https://i.ibb.co/dMqdQGy/micros.png")`,
          }}
          className="categories__item"
        >
          <div className="item__front">
            <span>
              <FormattedMessage
                id="char.Microfonos"
                defaultMessage="Microphones"
              />
            </span>
          </div>
        </Link>
        <Link
          to="/categories/Bumpers"
          style={{
            backgroundImage: `url("https://i.ibb.co/SnrGLcT/bumpers.png")`,
          }}
          className="categories__item"
        >
          <div className="item__front">
            <span>Bumpers</span>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default Categories;
