import React from "react";
import { FormattedMessage } from "react-intl";
import IMG1 from "../../assets/imgs/ing1.jpg";
import IMG2 from "../../assets/imgs/ing2.jpg";

import "./tecnic.css";

const Tecninc = () => {
  return (
    <section className="tecnic">
      <div className="tecnic__images">
        <img
          className="tecnic__image image1"
          src={IMG2}
          alt="ingeniera trabajando"
        />
        <img
          className="tecnic__image image2"
          src={IMG1}
          alt="ingeniera trabajando"
        />
      </div>
      <div className="tecnic__text">
        <h3>
          <FormattedMessage
            id="tecnic.title"
            defaultMessage="Specialized in providing you with the highest technology"
          />
        </h3>
        <div className="tecnic__figures">
          <div className="figures__square"></div>
          <div className="figures__line"></div>
        </div>
        <p>
          <FormattedMessage
            id="tecnic.description"
            defaultMessage="MRS always seeking to be part of the technological revolution, with specialists in the field we can provide you with high-tech products and excellent quality. For MRS it is a priority that each product exceeds technological, quality and durability expectations."
          />
        </p>
      </div>
    </section>
  );
};

export default Tecninc;
